export interface ProgressData<D = never> {
  duration: number;
  data: D;
}

export interface Progress<D = never> extends ProgressData<D> {
  value: number;
}

export enum ConnectionError {
  DEVICE_NOT_FOUND = 'Device not found.',
  WEB_USER_CANCELLED = 'User cancelled the requestDevice() chooser.',
  USER_CANCELLED = 'User cancelled',
  IOS_SCAN_TIMEOUT = 'Request cancelled',
  IOS_BLUETOOTH_DISABLED = 'Bluetooth is OFF',
  IOS_CONNECTION_NOT_FOUND = 'Bad device request',
  IOS_DEVICE_FORGOTTEN = 'Peer removed pairing information',
  CONNECTION_ERROR = 'Connection error',
  PAIRING_ERROR = 'Pairing error',
  OUT_OF_RANGE = 'Device out of range',
  IOS_BONDING_ERROR = 'GATT error',
  ANDROID_BONDING_ERROR = 'Bond request has been denied',
  IN_BOOTLOADER_STATE = `Device is in bootloader state.`,
}
